<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'巴林右旗' + `${townName + villageName}` + '防疫统计'" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_5_p h_full flex_column_between">
        <cun-lan-mian-yi
          :areaCode="villageId" 
          :parentAreaCode="townId"
          :parentLevel="level"
          :jobId="jobId"
          @changeCount="changeCount"
        />
      </div> 

      <!-- 2 -->
      <div class="w_32_5_p h_full flex_column_between">
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 relative">
          <component :is="componentName"></component>
        </div>

        <!-- 文字描述 -->
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_5 color_fff text_1">
          <div class="w_full flex_row_between p_10">
            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ villageName }}存栏数：
              <span class="font_number theme_color_orange ls_2">
                <animate-number from="0" :to="animalCount" :key="animalCount" duration="3000" />
              </span> 
            </div>

            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ villageName }}免疫数：
              <span class="font_number theme_color_green ls_2">
                <animate-number from="0" :to="fangYiCount" :key="fangYiCount" duration="3000" />
              </span> 
            </div>
          </div>

          <!-- xxx 村描述 -->
          <div class="w_full h_full-70 text_indent_2 ph_10 overflow_y_auto scroll_box">{{ content || '' }}</div>
        </div>
      </div> 

      <!-- 3 -->
      <div class="w_32_5_p h_full p_10 border_box bgs_full chart_box_bgi_2">
        <!-- 小组列表 -->
        <div class="full p_10 border_box" v-if="isGroup">
          <div class="w_full h_5_p text_left">
            <span class="jian_bian_text_1 text_1 fw_bold font_number">
              小组总数：{{ groupList.length }}
            </span>
          </div>

          <div class="scroll_box w_full h_95_p overflow_y_auto">
            <div class="mb_5 font_number w_full h_30 lh_30 text_1 color_fff" v-for="(item, index) in groupList" :key="index">
              <div class="w_full text_center cursor hover_main_color" @click="goToGroupDetail(item)">
                {{ item.groupName || '' }}
              </div>
            </div>
          </div>
        </div>

        <!-- 牧户列表 -->
        <div class="full" v-else>
          <div class="w_full flex_start flex_center" >
            <div class="w_40_p main_color font_number text_left">
              牧户总数：{{ muhuList.length }}
            </div>
            <div class="w_40_p pl_10">
              <!-- <el-input 
                v-model="queryParams.userName" 
                size="small" clearable filterable
                placeholder="牧户" 
                @change="selectMuHu"
                @clear="clearName()"
              /> -->

              <el-select 
                class="w_full_i" 
                v-model="muHuId" size="small" clearable 
                placeholder="牧户" filterable
                @change="selectMuHu"
                @clear="clearName()"
              >
                <el-option
                  v-for="item in muHuNameList" :key="item.userId"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div class="w_40_p pl_10">
              <el-input 
                v-model="queryParams.phone" 
                size="small" clearable filterable
                placeholder="电话" 
              />
            </div>
          </div>

          <div class="scroll_box w_full h_full-50 overflow_y_auto mt_10">
            <div
              class="w_full h_150 p_10 border_box flex_row_between mb_10" 
              v-for="(item, index) in muhuList" :key="index"
              @click="handleClickMuhu(item.id)"
            >
              <!-- 头像 -->
              <div class="w_120 h_150 overflow_hidden p_5 border_radius_10 relative">
                <img 
                  class="w_full border_radius_10 cursor absolute top_0 left_0" 
                  :src="filePath + item.avatarUrl" alt=""
                  v-if="item.avatarUrl"
                >
                <div class="full" v-else>
                  <img 
                    class="w_full border_radius_10 cursor" 
                    :src="def_user_img" alt=""
                  >
                </div>
              </div>

              <!-- 文字信息 -->
              <div class="info_box w_full-120 flex_column_start pl_20 cursor">
                <div class="text_1 color_fff">牧户姓名: <span class="main_color">{{ item.nickName || ''}}</span></div>
                <div class="text_1 color_fff">电话: <span class="font_number ls_2">{{ formatPhone(item.phone) || ''}}</span></div>
                <!-- <div class="text_1 color_fff">性别: {{ formatGender(item.gender) || ''}} </div> -->
                <div class="text_1 color_fff">其他: {{ item.address || ''}} </div>
              </div>
            </div>
          </div>  
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'
  import dataChartMixin from '@/mixins/data-charts.js'

  import { getGroupListByVillageId, getVillageInfo } from '@/api/base/base.js';
  import{ getUserList } from '@/api/mu-hu/mu-hu.js';

  export default {
    name: 'VillagePage',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin, dataChartMixin],
    data() {
      return {
        animalCount: null,
        fangYiCount: null,

        jobId: null,

        townId: null,
        townName: null,
        level: null,
        villageId: null,
        villageName: null,
        isGroup: false,

        queryParams: {
          userName: '',
          phone: ''
        },

        groupList: [],
        muhuList: [],
        targetList: [],
        muHuNameList: [],
        content: ''
      }
    },
    computed: {
      componentName() {
        let component = ''

        // 索博日嘎镇所有嘎查
        if (this.villageName == '塔西村') {
          component = 'TaXi'
        } else if (this.villageName == '索博日嘎嘎查村委员会') {
          component = 'SoBoRiGaVillage'
        } else if (this.villageName == '常兴村') {
          component = 'ChangXing'
        } else if (this.villageName == '乌兰绍荣嘎查村委员会') {
          component = 'WuLanShaoLeng'
        } else if (this.villageName == '巴根吐村') {
          component = 'BaGenTu'
        } else if (this.villageName == '辉腾高勒村') {
          component = 'HuiTongHe'
        } else if (this.villageName == '阿日山艾勒嘎查村委员会') {
          component = 'AShanAiLi'
        } else if (this.villageName == '阿日山高勒村') {
          component = 'AShanHe'
        } else if (this.villageName == '罕山沟村') {
          component = 'HanShanGou'
        } else if (this.villageName == '朝阳村') {
          component = 'ChaoYang'
        } else if (this.villageName == '荣生村') {
          component = 'RongSheng'
        } else if (this.villageName == '胡都格绍荣村') {
          component = 'HuDuGe'

          // 巴彦琥硕镇 所有嘎查
        } else if (this.villageName == '沙日嘎达巴村') {
          component = 'ShaRiGaDaBa'
        } else if (this.villageName == '山头村') {
          component = 'ShanTou'
        } else if (this.villageName == '四家村') {
          component = 'SiJia'
        } else if (this.villageName == '中组村') {
          component = 'ZhongZu'

          // 大板镇所有嘎查
        } else if (this.villageName == '浩饶钦嘎查村委员会') {
          component = 'HaoRaoQin'
        } else if (this.villageName == '太布呆嘎查') {
          component = 'TaiBuDai'
        } else if (this.villageName == '大冷村') {
          component = 'DaLeng'
        } else if (this.villageName == '大板村') {
          component = 'DaBanHamlet'
        } else if (this.villageName == '古力古台嘎查') {
          component = 'GuLiGuTai'
        } else if (this.villageName == '乌兰图嘎嘎查') {
          component = 'HongQi'
        } else if (this.villageName == '吉布吐村') {
          component = 'JiBuTu'
        } else if (this.villageName == '巴润哈日毛都嘎查村委员会') {
          component = 'XiHa'
        } else if (this.villageName == '温根吐嘎查') {
          component = 'WengGenTu'
        } else if (this.villageName == '阿日班格日嘎查村委员会') {
          component = 'ShiJiaZi'
        } else if (this.villageName == '苏艾勒嘎查村委员会') {
          component = 'SuAiLi'
        } else if (this.villageName == '昭胡都格嘎查') {
          component = 'ZhaoHuDuGe'
        } else if (this.villageName == '翁根毛都嘎查村委员会') {
          component = 'WengGenMaoDu'
        } else if (this.villageName == '套白村') {
          component = 'TaoBaiCun'
        } else if (this.villageName == '归勒斯台嘎查村委员会') {
          component = 'GuiLeSuTai'
        } else if (this.villageName == '胡斯台嘎查村委员会') {
          component = 'HuSiTai'

          // 西拉沐沦苏木所有嘎查
        } else if (this.villageName == '沙巴尔台嘎查村委员会') {
          component = 'ShaBaErTai'
        } else if (this.villageName == '塔本花嘎查村委员会') {
          component = 'TaBenHua'
        } else if (this.villageName == '呼特勒嘎查村委员会') {
          component = 'HuTeLe'
        } else if (this.villageName == '查干锡热嘎查村委员会') {
          component = 'ChaGanXiRe'
        } else if (this.villageName == '浩伊日毛都村') {
          component = 'HaoYiRiMaoDu'
        } else if (this.villageName == '珠腊沁嘎查村委员会') {
          component = 'ZhuLaQin'
        } else if (this.villageName == '查干沐沦嘎查村委员会') {
          component = 'ChaGanMuLun'
        } else if (this.villageName == '巴彦锡那村') {
          component = 'BaYanXiNa'
        } else if (this.villageName == '达马金村') {
          component = 'DaMaJin'
        } else if (this.villageName == '岗根村') {
          component = 'GangGen'
        } else if (this.villageName == '亚玛图村') {
          component = 'YaMaTu'


        } else if (this.villageName == '') {
          component = ''
        } else if (this.villageName == '') {
          component = ''
        } else {
          component = 'Def'
        }

        return component
      }
    },
    mounted() {
      let townId = this.$route.params.townId
      let townName = this.$route.params.townName
      let level = this.$route.params.level
      let villageId = this.$route.params.villageId
      let villageName = this.$route.params.villageName
      let isGroup = this.$route.params.isGroup
      
      this.townId = townId
      this.villageId = villageId
      this.townName = townName
      this.villageName = villageName
      // this.isGroup = isGroup
      this.level = level


      // 获取 xxx 村的 说明文字
      this.getVillageInfo(villageId)

      // 获取 嘎查中的 所有 牧户列表
      this.getUserList(this.villageId)

      // if (isGroup == true) {
      //   // 获取 当前嘎查（村）中的 所有 小组列表
      //   this.getGroupListByVillageId(villageId)
      // } else {
      //   // 获取 嘎查中的 所有 牧户列表
      //   this.getUserList(this.villageId)
      // }
    },
    methods: {  
      selectJob(jobId) {
        this.jobId = jobId;
      },

      // 监听 地区的存栏防疫 总数
      changeCount(obj) {
        this.animalCount = obj.animalCount
        this.fangYiCount = obj.fangYiCount
      },

      // 获取 xxx 村的 说明文字
      async getVillageInfo(id) {
        let res = await getVillageInfo(id)
        try {
          if (res.code == 1000) {
            this.content = res.data.content
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 嘎查 中的 小组列表
      async getGroupListByVillageId(villageId) {
        let res = await getGroupListByVillageId(villageId)
        try {
          if (res.code == 1000) {
            this.groupList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 跳转到 小组页
      goToGroupDetail(item) {
        let townId = item.townId
        let townName = item.townName
        let villageId = item.villageId
        let villageName = item.villageName
        let groupId = item.id
        let groupNo = item.groupNo
        let groupName = item.groupName

        if (item.content && item.content.length) {
          sessionStorage.setItem('groupContent', item.content)
        } else {
          sessionStorage.setItem('groupContent', '')
        }

        this.$router.push(
          `/groupFyPage/${townId}/${townName}/${villageId}/${villageName}/5/${groupId}/${groupName}`
        )
      },

      // 获取 村中的 牧户列表
      async getUserList(villageId) {
        let res = await getUserList(villageId)
        try {
          if (res.code == 1000) {
            this.muhuList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 小组 中的 牧户列表
      async getUserList(villageId) {
        let res = await getUserList(villageId)
        try {
          if (res.code == 1000) {
            this.targetList = res.data
            this.muhuList = res.data

            let muHuNameList = []
            res.data.map(item => {
              muHuNameList.push({ id: item.id, name: item.nickName})
            })

            this.muHuNameList = Array.from(new Set(muHuNameList.map(JSON.stringify)), JSON.parse)
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 跳转到牧户详情页
      handleClickMuhu(id) {
        this.goToMuHuPage(this.townId, this.villageId, null, id)
      },

      selectMuHu(userId) {
        this.muhuList = this.targetList.filter(item => item.id == userId)
      },

      clearName() {
        this.muhuList = this.targetList
      },

      inputPhone(val) {
        if (!val) {
          this.muhuList = this.targetList
        }
        this.muhuList = this.targetList.filter(item => item.phone == val)
      },

      clearPhone() {
        this.muhuList = this.targetList
      }
    }
  }
</script>